import React from 'react';
import { OnChangePlugin as OnChangeLexicalPlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useOnChange } from './hooks';

type OnChangePluginProps = {
  onChange: (htmlString: string) => void;
};

export const OnChangePlugin = ({ onChange }: OnChangePluginProps) => {
  const handleChange = useOnChange(onChange);

  return <OnChangeLexicalPlugin onChange={handleChange} ignoreSelectionChange />;
};
