import { useClickOutside } from 'app/utils/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ColorChangeHandler } from 'react-color';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const defaultFontBackgroundColor = 'transparent';

export const useFontBackgroundColor = () => {
  const [editor] = useLexicalComposerContext();

  const [fontBackgroundColor, setFontBackgroundColor] = useState(defaultFontBackgroundColor);

  const fontBackgroundColorDropdownRef = useRef<HTMLDivElement>(null);
  const fontBackgroundColorButtonRef = useRef<HTMLButtonElement>(null);

  const [isFontBackgroundColorDropdownOpen, setIsFontBackgroundColorDropdownOpen] = useState(false);

  const closeFontBackgroundColorDropdown = () => {
    setIsFontBackgroundColorDropdownOpen(false);
  };

  const openFontBackgroundColorDropdown = () => {
    setIsFontBackgroundColorDropdownOpen(true);
  };

  useClickOutside(fontBackgroundColorDropdownRef, closeFontBackgroundColorDropdown);

  const changeFontBackgroundColor: ColorChangeHandler = ({ hex }, e) => {
    // need this preventDefault to prevent the editor from losing focus of the selection
    e.preventDefault();
    editor.update(() => {
      const selection = $getSelection();

      if (selection !== null) {
        $patchStyleText(selection, {
          'background-color': hex,
        });
      }
    });
  };

  const changeDefaultBackgroundColor = (color: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    changeFontBackgroundColor({ hex: color }, event);
  };

  const removeBackgroundColor = () => {
    editor.update(() => {
      const selection = $getSelection();

      if (selection !== null) {
        $patchStyleText(selection, {
          'background-color': defaultFontBackgroundColor,
        });
      }
    });
  };

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      setFontBackgroundColor(
        $getSelectionStyleValueForProperty(selection, 'background-color', defaultFontBackgroundColor),
      );
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            $updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateToolbar();
          });
        }),
      ),
    [$updateToolbar, editor],
  );

  return {
    values: {
      fontBackgroundColor,
      isFontBackgroundColorDropdownOpen,
    },
    refs: {
      fontBackgroundColorButtonRef,
      fontBackgroundColorDropdownRef,
    },
    handlers: {
      openFontBackgroundColorDropdown,
      closeFontBackgroundColorDropdown,
      changeFontBackgroundColor,
      removeBackgroundColor,
      changeDefaultBackgroundColor,
    },
  };
};
