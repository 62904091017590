/* eslint-disable no-bitwise */
// This plugin code was copied form original Lexical example and slightly refactored
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $isTableCellNode,
  $isTableRowNode,
  TableCellNode,
  TableDOMCell,
  getDOMCellFromTarget,
} from '@lexical/table';
import { calculateZoomLevel } from '@lexical/utils';
import { $getNearestNodeFromDOMNode } from 'lexical';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

type MousePosition = {
  x: number;
  y: number;
};

type MouseDraggingDirection = 'left' | 'bottom';

const MIN_COLUMN_WIDTH = 75;
const MIN_ROW_HEIGHT = 33;

export const useTableResize = () => {
  const [editor] = useLexicalComposerContext();

  const targetRef = useRef<HTMLElement | null>(null);
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const tableRectRef = useRef<DOMRect | null>(null);
  const mouseStartPosRef = useRef<MousePosition | null>(null);

  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [activeCell, setActiveCell] = useState<TableDOMCell | null>(null);
  const [mouseCurrentPosition, setMouseCurrentPosition] = useState<MousePosition | null>(null);
  const [draggingDirection, setDraggingDirection] = useState<MouseDraggingDirection | null>(null);

  const resetState = useCallback(() => {
    setActiveCell(null);
    targetRef.current = null;
    setDraggingDirection(null);
    mouseStartPosRef.current = null;
    tableRectRef.current = null;
  }, []);

  const isMouseDownOnEvent = ({ buttons }: MouseEvent) => (buttons & 1) === 1;

  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      const { target } = event;

      if (draggingDirection) {
        setMouseCurrentPosition({
          x: event.clientX,
          y: event.clientY,
        });
        return;
      }
      // checking if mouse is pressed down on the event (left button click)
      setIsMouseDown(isMouseDownOnEvent(event));
      if (resizerRef.current && resizerRef.current.contains(target as Node)) {
        return;
      }

      if (targetRef.current !== target) {
        targetRef.current = target as HTMLElement;
        const cell = getDOMCellFromTarget(target as HTMLElement);

        if (cell && activeCell !== cell) {
          editor.update(() => {
            const tableCellNode = $getNearestNodeFromDOMNode(cell.elem);
            if (!tableCellNode) {
              throw new Error('TableCellResizer: Table cell node not found.');
            }

            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
            const tableElement = editor.getElementByKey(tableNode.getKey());

            if (!tableElement) {
              throw new Error('TableCellResizer: Table element not found.');
            }

            targetRef.current = target as HTMLElement;
            tableRectRef.current = tableElement.getBoundingClientRect();
            setActiveCell(cell);
          });
        } else if (cell == null) {
          resetState();
        }
      }
    };

    const onMouseDown = () => {
      setIsMouseDown(true);
    };

    const onMouseUp = () => {
      setIsMouseDown(false);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [activeCell, draggingDirection, editor, resetState]);

  const isHeightChanging = (direction: MouseDraggingDirection) => direction === 'bottom';

  const updateRowHeight = useCallback(
    (newHeight: number) => {
      if (!activeCell) {
        throw new Error('TableCellResizer: Expected active cell.');
      }

      editor.update(
        () => {
          const tableCellNode = $getNearestNodeFromDOMNode(activeCell.elem);
          if (!$isTableCellNode(tableCellNode)) {
            throw new Error('TableCellResizer: Table cell node not found.');
          }

          const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

          const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

          const tableRows = tableNode.getChildren();

          if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
            throw new Error('Expected table cell to be inside of table row.');
          }

          const tableRow = tableRows[tableRowIndex];

          if (!$isTableRowNode(tableRow)) {
            throw new Error('Expected table row');
          }

          tableRow.setHeight(newHeight);
        },
        { tag: 'skip-scroll-into-view' },
      );
    },
    [activeCell, editor],
  );

  const updateColumnWidth = useCallback(
    (newWidth: number) => {
      if (!activeCell) {
        throw new Error('TableCellResizer: Expected active cell.');
      }
      editor.update(
        () => {
          const tableCellNode = $getNearestNodeFromDOMNode(activeCell.elem);
          if (!$isTableCellNode(tableCellNode)) {
            throw new Error('TableCellResizer: Table cell node not found.');
          }

          const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

          const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);

          const tableRows = tableNode.getChildren();

          for (let r = 0; r < tableRows.length; r++) {
            const tableRow = tableRows[r];

            if (!$isTableRowNode(tableRow)) {
              throw new Error('Expected table row');
            }

            const rowCells = tableRow.getChildren<TableCellNode>();
            const rowCellsSpan = rowCells.map((cell) => cell.getColSpan());

            const aggregatedRowSpans = rowCellsSpan.reduce((rowSpans: number[], cellSpan) => {
              const previousCell = rowSpans[rowSpans.length - 1] ?? 0;
              rowSpans.push(previousCell + cellSpan);
              return rowSpans;
            }, []);
            const rowColumnIndexWithSpan = aggregatedRowSpans.findIndex(
              (cellSpan: number) => cellSpan > tableColumnIndex,
            );

            if (rowColumnIndexWithSpan >= rowCells.length || rowColumnIndexWithSpan < 0) {
              throw new Error('Expected table cell to be inside of table row.');
            }

            const tableCell = rowCells[rowColumnIndexWithSpan];

            if (!$isTableCellNode(tableCell)) {
              throw new Error('Expected table cell');
            }

            tableCell.setWidth(newWidth);
          }
        },
        { tag: 'skip-scroll-into-view' },
      );
    },
    [activeCell, editor],
  );

  const mouseUpHandler = useCallback(
    (direction: MouseDraggingDirection) => {
      const handler = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (!activeCell) {
          throw new Error('TableCellResizer: Expected active cell.');
        }

        if (mouseStartPosRef.current) {
          const { x, y } = mouseStartPosRef.current;

          if (activeCell === null) {
            return;
          }
          const zoom = calculateZoomLevel(event.target as Element);

          if (isHeightChanging(direction)) {
            const { height } = activeCell.elem.getBoundingClientRect();
            const heightChange = Math.abs(event.clientY - y) / zoom;

            const isShrinking = direction === 'bottom' && y > event.clientY;

            updateRowHeight(Math.max(isShrinking ? height - heightChange : heightChange + height, MIN_ROW_HEIGHT));
          } else {
            const computedStyle = getComputedStyle(activeCell.elem);
            let width = activeCell.elem.clientWidth; // width with padding
            width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            const widthChange = Math.abs(event.clientX - x) / zoom;

            const isShrinking = direction === 'left' && x > event.clientX;

            updateColumnWidth(Math.max(isShrinking ? width - widthChange : widthChange + width, MIN_COLUMN_WIDTH));
          }

          resetState();
          document.removeEventListener('mouseup', handler);
        }
      };
      return handler;
    },
    [activeCell, resetState, updateColumnWidth, updateRowHeight],
  );

  const toggleResize = useCallback(
    (direction: MouseDraggingDirection): MouseEventHandler<HTMLDivElement> =>
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!activeCell) {
          throw new Error('TableCellResizer: Expected active cell.');
        }

        mouseStartPosRef.current = {
          x: event.clientX,
          y: event.clientY,
        };
        setMouseCurrentPosition(mouseStartPosRef.current);
        setDraggingDirection(direction);

        document.addEventListener('mouseup', mouseUpHandler(direction));
      },
    [activeCell, mouseUpHandler],
  );

  const getResizers = useCallback(() => {
    if (activeCell) {
      const { height, width, top, left } = activeCell.elem.getBoundingClientRect();
      const zoom = calculateZoomLevel(activeCell.elem);

      const styles = {
        bottom: {
          backgroundColor: 'none',
          cursor: 'row-resize',
          height: '10px',
          left: `${window.scrollX + left}px`,
          top: `${window.scrollY + top + height - 5}px`,
          width: `${width}px`,
          'z-index': '9999',
        },
        left: {
          backgroundColor: 'none',
          cursor: 'col-resize',
          height: `${height}px`,
          left: `${window.scrollX + left + width - 5}px`,
          top: `${window.scrollY + top}px`,
          width: '10px',
          'z-index': '9999',
        },
      };

      const tableRect = tableRectRef.current;

      if (draggingDirection && mouseCurrentPosition && tableRect) {
        if (isHeightChanging(draggingDirection)) {
          styles[draggingDirection].left = `${window.scrollX + tableRect.left}px`;
          styles[draggingDirection].top = `${window.scrollY + mouseCurrentPosition.y / zoom}px`;
          styles[draggingDirection].height = '3px';
          styles[draggingDirection].width = `${tableRect.width}px`;
        } else {
          styles[draggingDirection].top = `${window.scrollY + tableRect.top}px`;
          styles[draggingDirection].left = `${window.scrollX + mouseCurrentPosition.x / zoom}px`;
          styles[draggingDirection].width = '3px';
          styles[draggingDirection].height = `${tableRect.height}px`;
        }

        styles[draggingDirection].backgroundColor = '#adf';
      }

      return styles;
    }

    return {
      bottom: null,
      left: null,
      right: null,
      top: null,
    };
  }, [activeCell, draggingDirection, mouseCurrentPosition]);

  const resizerStyles = getResizers();

  return {
    resizerRef,
    activeCell,
    resizerStyles,
    isMouseDown,
    toggleResize,
  };
};
