import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useState } from 'react';

export const useToolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [isActive, setIsActive] = useState(false);

  return {
    isActive,
    setIsActive,
    isEditable: editor.isEditable,
  };
};
