import React, { ReactNode } from 'react';
import { useFloatingTextFormatter } from './hooks';
import { FloatingTextFormatterEditor } from './components';

type FloatingTextFormatterPluginProps = {
  floatingAnchorElement: HTMLDivElement;
};

export const FloatingTextFormatterPlugin = ({ floatingAnchorElement }: FloatingTextFormatterPluginProps): ReactNode => {
  const { isText, isBold, isItalic } = useFloatingTextFormatter();

  if (!isText) return null;
  return (
    <FloatingTextFormatterEditor isBold={isBold} isItalic={isItalic} floatingAnchorElement={floatingAnchorElement} />
  );
};
