/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { PUIButtonIcon, PUIText } from 'ui';
import { useTranslation } from 'react-i18next';
import { stopPropagation } from 'app/utils';
import Loader from 'app/components/core/preloader';
import theme from 'ui/utils/theme';
import { useImagePreview } from './hooks';
import s from './styles.module.scss';

type ImagePreviewProps = {
  imageId: string;
  closePreview: () => void;
  width: 'inherit' | number;
  height: 'inherit' | number;
};

export const ImagePreview = ({ imageId, closePreview, width, height }: ImagePreviewProps) => {
  const { t } = useTranslation();
  const { downloadImage, document, isFileLinkLoading, isFileLoading, fileURL } = useImagePreview(imageId);

  if (isFileLoading || isFileLinkLoading || !document) return <Loader />;
  return (
    <div className={s.popup} onClick={stopPropagation}>
      <div className={s.header}>
        <PUIButtonIcon icon="remove" iconSize="md" iconLeft onClick={closePreview}>
          {t('backTo')}
        </PUIButtonIcon>
        <PUIText size={theme.typography.sizes.l} weight={theme.typography.weights.medium} className={s.name}>
          {document.originalName}
        </PUIText>
        <PUIButtonIcon icon="download" iconSize="md" iconLeft disabled={isFileLoading} onClick={downloadImage}>
          {t('download')}
        </PUIButtonIcon>
      </div>
      <div className={s.wrapper}>
        <img src={fileURL} height={height} width={width} alt="Preview" />
      </div>
    </div>
  );
};
