import React from 'react';
import cx from 'classnames';
import MoreIcon from './static/more-vertical.svg';
import { useExtra } from './hooks';
import s from './styles.module.scss';
import { Table } from '../table';
import { Indent } from '../indent';
import { Outdent } from '../outdent';

export const Extra = () => {
  const { wrapperRef, dropdownRef, isDropdownOpen, openDropdown, closeDropdown } = useExtra();

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <button
        className={cx('reset-button', s.button)}
        aria-label="More options"
        type="button"
        onClick={isDropdownOpen ? closeDropdown : openDropdown}
      >
        <MoreIcon />
      </button>
      {isDropdownOpen && (
        <div className={s.dropdown} ref={dropdownRef}>
          <Table closeDropdown={closeDropdown} />
          <Indent />
          <Outdent />
        </div>
      )}
    </div>
  );
};
