import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_IMAGE_COMMAND } from 'app/components/core/editor/commands';
import { useUploadPublicFile } from 'data/hooks';
import { getPrivateFile } from 'data/index';
import { ChangeEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui';

export const useImage = () => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutate: upload, isLoading } = useUploadPublicFile();

  const showFilePopup = () => {
    // Trigger the file input dialog
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const uploadImage: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    upload(
      { formData },
      {
        onSuccess: (res) => {
          const src = getPrivateFile(res.token);
          if (!src) return;

          editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src, id: res.id });
        },
        onError: () => {
          toast.error(t('upload_failed'));
        },
      },
    );
  };

  return {
    uploadImage,
    fileInputRef,
    showFilePopup,
    isLoading,
  };
};
