import React from 'react';
import cx from 'classnames';
import Bold from '../../../../static/bold.svg';
import Italic from '../../../../static/italic.svg';
import { useFloatingTextFormatterEditor } from './hooks';
import s from './styles.module.scss';
import { FontColors } from '../../../toolbar/components';

type FloatingTextFormatterEditorProps = {
  isBold: boolean;
  isItalic: boolean;
  floatingAnchorElement: HTMLDivElement;
};

export const FloatingTextFormatterEditor = ({
  isBold,
  isItalic,
  floatingAnchorElement,
}: FloatingTextFormatterEditorProps) => {
  const { textFormatterEditor, formatBold, formatItalic } = useFloatingTextFormatterEditor(floatingAnchorElement);

  return (
    <div ref={textFormatterEditor} className={s.editor}>
      <button
        type="button"
        title="Bold"
        onClick={formatBold}
        className={cx('reset-button', s.button, isBold && s.buttonActive)}
      >
        <Bold />
      </button>
      <button
        type="button"
        title="Italic"
        onClick={formatItalic}
        className={cx('reset-button', s.button, isItalic && s.buttonActive)}
      >
        <Italic />
      </button>
      <FontColors isFloatingEditor />
    </div>
  );
};
