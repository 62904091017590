import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_DYNAMIC_VALUE_COMMAND } from 'app/components/core/editor/commands';
import { DynamicValueOption, Variable } from '../types';

type OptionsGroup = {
  label: string;
  options: DynamicValueOption[];
};

const transformToGroupedOptions = (variables: Variable[]) => {
  const result: Array<OptionsGroup | DynamicValueOption> = [];
  let currentGroup: OptionsGroup | null = null;

  variables.forEach((item) => {
    if (item.text && !item.key) {
      // New group found
      if (currentGroup) {
        // Push the previous group to the result
        result.push(currentGroup);
      }
      // Start a new group
      currentGroup = { label: item.text, options: [] };
    } else if (item.key && item.text) {
      // Item belongs to the current group
      const option = { label: item.text, value: item.key };
      if (currentGroup) {
        currentGroup.options.push(option);
      } else {
        // No current group, so add as a root option
        result.push(option);
      }
    }
  });

  // Push the last group if exists
  if (currentGroup) {
    result.push(currentGroup);
  }

  return result;
};

export const useDynamicValue = (variables: Variable[], isDocumentBuilder?: boolean) => {
  const [editor] = useLexicalComposerContext();

  const insert = ({ value = '', label = '' }) => {
    editor.dispatchCommand(INSERT_DYNAMIC_VALUE_COMMAND, {
      text: label,
      valueType: isDocumentBuilder ? 'with-value' : 'placeholder',
    });
  };

  const options = transformToGroupedOptions(variables?.filter((item) => !item.text?.includes(String(null))));

  return {
    options,
    insert,
  };
};
