import React from 'react';
import { Icon } from 'ui';
import Select, { GroupTypeBase, OptionProps, components } from 'react-select';
import { AlignOption, alignIconsMap, useAlign } from './hooks';
import s from './styles.module.scss';

const Control = ({ children, ...props }) => {
  const [{ value }] = props.getValue();
  const AlignIcon = alignIconsMap[value];

  return (
    <components.Control className={s.control} {...props}>
      <div className={s.children}>{children}</div>
      <AlignIcon />
      <Icon className={s.icon} icon="chevronDown" />
    </components.Control>
  );
};

const Option = (props: OptionProps<AlignOption, false, GroupTypeBase<AlignOption>>) => {
  const { value } = props;
  const AlignIcon = alignIconsMap[value];

  return (
    <components.Option {...props} className={s.option}>
      <AlignIcon />
    </components.Option>
  );
};

const styles = {
  menuPortal: (provided) => ({
    ...provided,
    'z-index': '9999',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    borderColor: 'transparent',
  }),
};

export const Align = () => {
  const { align, options, onChange } = useAlign();

  return (
    <Select
      styles={styles}
      isSearchable={false}
      options={options}
      className={s.select}
      components={{ Control, Option }}
      onChange={onChange}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      value={options.find((option) => option.value === align)}
    />
  );
};
