import React from 'react';
import { PUITextInput } from 'ui';
import cx from 'classnames';
import s from './styles.module.scss';

type EditLinkFormProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  handleSubmit: () => void;
  closeForm: () => void;
};

export const EditLinkForm = ({ value, onChange, onKeyDown, closeForm, handleSubmit }: EditLinkFormProps) => (
  <div className={s.form}>
    <PUITextInput className={s.input} value={value} onChange={onChange} onKeyDown={onKeyDown} id="link-input" />
    <button onClick={closeForm} aria-label="Close form" className={cx('reset-button', s.button)} type="button">
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18 17.94 6M18 18 6.06 6"
        />
      </svg>
    </button>
    <button onClick={handleSubmit} aria-label="Add link" className={cx('reset-button', s.button)} type="button">
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 11.917 9.724 16.5 19 7.5"
        />
      </svg>
    </button>
  </div>
);
