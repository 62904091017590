/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ComponentProps, DetailedHTMLProps, HTMLAttributes, HTMLProps } from 'react';
import cx from 'classnames';
import s from './styles.module.scss';
import {
  Align,
  History,
  Image,
  Link,
  TextFormatting,
  FontSize,
  FontColors,
  DynamicValue,
  Lists,
  Extra,
} from './components';
import { useToolbar } from './hooks';

type ToolbarPluginProps = {
  variables?: {
    key?: string | undefined;
    text?: string | undefined;
  }[];
  isDocumentBuilder?: boolean;
  toolbarProps?: HTMLProps<HTMLDivElement>;
};

export const ToolbarPlugin = ({ variables, isDocumentBuilder, toolbarProps = {} }: ToolbarPluginProps) => {
  const { className, ...restToolbarProps } = toolbarProps;
  const { isEditable, isActive, setIsActive } = useToolbar();

  return (
    <div
      className={cx(s.toolbar, !isEditable && s.toolbarDisabled, isActive && s.toolbarActive, className  && className)}
      {...restToolbarProps}
    >
      <History />
      <FontSize />
      <FontColors setIsActive={setIsActive} />
      <TextFormatting />
      <Lists />
      <Align />
      <Image />
      <Link />
      <Extra />
      {/* <BlockType /> */}
      {/* <FontFamily /> */}
      {/* <Indentation /> */}
      {/* <ClearFormatting /> */}
      {/* <Table /> */}
      {variables && <DynamicValue variables={variables} isDocumentBuilder={isDocumentBuilder} />}
    </div>
  );
};
