/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Icon } from 'ui';
import { useTableContextMenu } from './hooks';
import s from './styles.module.scss';
import { Menu } from './components';

type TableContextMenuPluginProps = {
  floatingAnchorElement: HTMLDivElement;
};

export const TableContextMenuPlugin = ({ floatingAnchorElement }: TableContextMenuPluginProps) => {
  const { contextMenuButtonRef, contextMenuWrapperRef, tableCellNode, isMenuOpen, showMenu, closeMenu } =
    useTableContextMenu(floatingAnchorElement);

  return (
    <div className={s.wrapper} ref={contextMenuWrapperRef}>
      {tableCellNode && (
        <>
          <button onClick={showMenu} type="button" ref={contextMenuButtonRef} className={s.button}>
            <Icon size="sm" icon="chevronDown" />
          </button>
          {isMenuOpen && <Menu closeMenu={closeMenu} tableCellNode={tableCellNode} />}
        </>
      )}
    </div>
  );
};
