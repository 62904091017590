import React from 'react';
import cx from 'classnames';
import s from './styles.module.scss';
import { defaultColors } from '../../constants';

type DefaultColorsProps = {
  onChange: (color: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
};

export const DefaultColors = ({ onChange }: DefaultColorsProps) => {
  return (
    <ul className={s.defaultColors}>
      {defaultColors.map((color) => (
        <button
          key={color}
          className={cx(s.color, 'reset-button')}
          style={{ backgroundColor: color }}
          aria-label={`Change color to ${color}`}
          onClick={(e) => onChange(color, e)}
        ></button>
      ))}
    </ul>
  );
};
