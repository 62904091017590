import React from 'react';
import Select, { components } from 'react-select';
import { Icon, PUIText } from 'ui';
import s from './styles.module.scss';
import { useFontSize } from './hooks';

const styles = {
  menuPortal: (provided) => ({
    ...provided,
    'z-index': '9999',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    borderColor: 'transparent',
  }),
};

const Control = ({ children, ...props }) => (
  <components.Control className={s.control} {...props}>
    <PUIText className={s.label}>{children[0]}</PUIText>
    <Icon className={s.icon} icon="chevronDown" />
  </components.Control>
);

export const FontSize = () => {
  const { options, onChange, fontSize } = useFontSize();

  return (
    <Select
      styles={styles}
      isSearchable={false}
      options={options}
      className={s.select}
      components={{ Control }}
      onChange={onChange}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      value={options.find((option) => option.value === fontSize)}
    />
  );
};
