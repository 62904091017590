/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import {
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
} from 'lexical';

export type DynamicValueNodeValueType = 'placeholder' | 'with-value' | 'empty';

export type DynamicValuePayload = {
  text: string;
  valueType: DynamicValueNodeValueType;
  key?: NodeKey;
};

export type SerializedMentionNode = Spread<
  {
    valueType: DynamicValueNodeValueType;
  },
  SerializedTextNode
>;

const getClassNameByValueType = (valueType: DynamicValueNodeValueType, config: EditorConfig): string => {
  switch (valueType) {
    case 'placeholder':
      return config.theme.dynamicValue;
    case 'with-value':
      return config.theme.dynamicValueWithValue;
    case 'empty':
      return config.theme.dynamicValueEmpty;
    default:
      return config.theme.dynamicValue;
  }
};

// This is a helper function to get the value type based on the class name of the TINY element
const getValueTypeByClassName = (className: string): DynamicValueNodeValueType => {
  switch (className) {
    case 'replaced-variable':
      return 'with-value';
    case 'variable':
      return 'placeholder';
    case 'empty-variable':
      return 'empty';
    default:
      return 'placeholder';
  }
};

export class DynamicValueNode extends TextNode {
  __valueType: DynamicValueNodeValueType = 'placeholder';

  static getType(): string {
    return 'dynamic-value';
  }

  static clone(node: DynamicValueNode): DynamicValueNode {
    return new DynamicValueNode(node.__text, node.__valueType, node.__key);
  }

  constructor(text: string, valueType: DynamicValueNodeValueType, key?: NodeKey) {
    super(text, key);
    this.__valueType = valueType;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('mark');

    element.textContent = this.__text;

    const className = getClassNameByValueType(this.__valueType, config);
    element.classList.add(className);

    return element;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      mark: () => ({
        conversion: convertDynamicValueElement,
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode: SerializedMentionNode): DynamicValueNode {
    const node = $createDynamicValueNode({ text: serializedNode.text, valueType: serializedNode.valueType });

    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      valueType: this.__valueType,
      type: DynamicValueNode.getType(),
      version: 1,
    };
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  canInsertTextBefore(): boolean {
    return false;
  }
}

export const $createDynamicValueNode = ({ text, valueType, key }: DynamicValuePayload): DynamicValueNode =>
  new DynamicValueNode(text, valueType, key);

const convertDynamicValueElement = (domNode: Node): null | DOMConversionOutput => {
  const mark = domNode as HTMLElement;
  const { textContent, className } = mark;

  const valueType = getValueTypeByClassName(className);

  const node = $createDynamicValueNode({ text: textContent || '', valueType });

  return { node };
};
