import { useEffect } from 'react';
import { $createParagraphNode, $createTextNode, $getRoot, LexicalNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';

export const useInitialHtmlContent = (initialHtmlContent: string) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      // Firstly parse the initial HTML string into DOM
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtmlContent, 'text/html');
      const { body } = dom;

      let nodes: LexicalNode[];
      if (body.childNodes.length === 1 && body.childNodes[0].nodeType === Node.TEXT_NODE) {
        // If the parsed content is a single text node, wrap it in a ParagraphNode
        const paragraphNode = $createParagraphNode();
        paragraphNode.append($createTextNode(body.textContent || ''));
        nodes = [paragraphNode];
      } else {
        // Otherwise, generate nodes from the DOM
        nodes = $generateNodesFromDOM(editor, dom);
      }

      // Clear the root node (by default root node has 1 empty paragraph) and append the new nodes
      const root = $getRoot();
      root.clear();
      nodes.forEach((n) => root.append(n));
      root.selectEnd();
    });
  }, [initialHtmlContent]);
};
