import { sanitizeUrl } from 'app/components/core/editor/utils';
import React from 'react';
import { PUIButtonIcon } from 'ui';
import s from './styles.module.scss';

type LinkPreviewProps = {
  linkUrl: string;
  showEditMode: () => void;
  deleteLink: () => void;
};

export const LinkPreview = ({ linkUrl, showEditMode, deleteLink }: LinkPreviewProps) => (
  <div className={s.preview}>
    <a href={sanitizeUrl(linkUrl)} target="_blank" rel="noopener noreferrer">
      {linkUrl}
    </a>
    <PUIButtonIcon icon="edit" type="button" onMouseDown={(event) => event.preventDefault()} onClick={showEditMode} />
    <PUIButtonIcon icon="trash2" type="button" onMouseDown={(event) => event.preventDefault()} onClick={deleteLink} />
  </div>
);
