/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import {
  $applyNodeReplacement,
  COMMAND_PRIORITY_EDITOR,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  DecoratorNode,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import React, { ReactElement } from 'react';
import { Image } from './component';

export type SerializedImageNode = Spread<
  {
    src: string;
    id: string;
    height?: number;
    width?: number;
    style?: string;
  },
  SerializedLexicalNode
>;

export interface ImagePayload {
  height?: number;
  key?: NodeKey;
  src: string;
  id: string;
  width?: number;
  style?: string;
}

const convertImageElement = (domNode: Node): null | DOMConversionOutput => {
  const img = domNode as HTMLImageElement;
  const {
    src,
    id,
    width,
    height,
    style: { width: inlineWidth, height: inlineHeight, cssText },
  } = img;

  const node = $createImageNode({
    id,
    src,
    style: cssText,
    height: height || inlineHeight,
    width: width || inlineWidth,
  });

  return { node };
};

export class ImageNode extends DecoratorNode<ReactElement> {
  __src: string;

  __width: 'inherit' | number;

  __height: 'inherit' | number;

  __id: string;

  __style?: string;

  static getType(): string {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__id, node.__width, node.__height, node.__key, node.__style);
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { height, width, src, style, id } = serializedNode;
    const node = $createImageNode({
      height,
      id,
      src,
      width,
      style,
    });

    return node;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: COMMAND_PRIORITY_EDITOR,
      }),
    };
  }

  constructor(
    src: string,
    id: string,
    width?: 'inherit' | number,
    height?: 'inherit' | number,
    key?: NodeKey,
    style?: string,
  ) {
    super(key);
    this.__src = src;
    this.__id = id;
    this.__width = width || 'inherit';
    this.__height = height || 'inherit';
    this.__style = style;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img');

    element.setAttribute('src', this.__src);
    element.setAttribute('id', this.__id);
    element.setAttribute('width', this.__width.toString());
    element.setAttribute('height', this.__height.toString());
    if (this.__style) element.setAttribute('style', this.__style);

    return { element };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement('span');
    const { theme } = config;
    const className = theme.image;

    if (className !== undefined) {
      span.className = className;
    }

    return span;
  }

  updateDOM(): false {
    return false;
  }

  exportJSON(): SerializedImageNode {
    return {
      version: 1,
      type: 'image',
      src: this.getSrc(),
      id: this.__id,
      style: this.__style,
      height: this.__height === 'inherit' ? 0 : this.__height,
      width: this.__width === 'inherit' ? 0 : this.__width,
    };
  }

  setWidthAndHeight(width: 'inherit' | number, height: 'inherit' | number): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  getSrc(): string {
    return this.__src;
  }

  decorate(): JSX.Element {
    return (
      <Image
        src={this.__src}
        nodeKey={this.getKey()}
        id={this.__id}
        width={this.__width}
        height={this.__height}
        style={this.__style}
      />
    );
  }
}

export const $createImageNode = ({ height, src, id, width, key, style }: ImagePayload): ImageNode =>
  $applyNodeReplacement(new ImageNode(src, id, width, height, key, style));

export const $isImageNode = (node: LexicalNode | null | undefined): node is ImageNode => node instanceof ImageNode;
