import { ChangeEventHandler, useState, useEffect, useCallback } from 'react';
import { TextAreaProps, TextInputProps } from '@panenco/ui';
import { useDebouncedCallback } from 'use-debounce';

const INPUT_DELAY = 200;

export const useFastTextField = ({
  value,
  onChange,
}: TextInputProps | TextAreaProps): [
  string,
  (ChangeEventHandler<HTMLInputElement> & ChangeEventHandler<HTMLTextAreaElement>) | undefined,
] => {
  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    if (value != null) {
      setInnerValue(value as string);
    } else {
      setInnerValue('');
    }
  }, [value]);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event);
      }
    },
    INPUT_DELAY,
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
      event.persist();

      const newValue = event.currentTarget.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    },
    [],
  );

  return [innerValue, handleOnChange];
};
