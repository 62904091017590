import queryString from 'qs';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import saveBlob from 'data/utils/actions';
import { fileKeys, recordKeys } from 'data/utils/hookKeys';

import { DocumentFile, File } from 'data/types';

import { getStoredUserId } from 'data/utils/userStorage';
import { FilePurpose } from 'data/enums';
import { AxiosError } from 'axios';
import { defaultDocumentSearchParams, IRecordsDocumentsParams } from './records';
import * as api from '../actions-query';

export const useUploadFile = (params?: {
  purpose: FilePurpose;
}): UseMutationResult<File, any, { fields: { files: FormData | string | Blob } }, unknown> =>
  useMutation(({ fields }) => {
    const { files } = fields;
    const queryParams = queryString.stringify(params);

    const userId = getStoredUserId();

    const formData = files instanceof FormData ? files : new FormData();
    if (files instanceof FormData === false) formData.append('file', files);

    return api.uploadFile(userId, formData, queryParams);
  });

export const useUploadPublicFile = (): UseMutationResult<
  File,
  AxiosError,
  { formData: FormData; progressFn?: (percent: number) => void },
  unknown
> => useMutation(({ formData, progressFn }) => api.uploadFilePublic(formData, progressFn));

export const useFile = (fileId: string) =>
  useQuery<Blob, AxiosError>(fileKeys.details(fileId), () => api.downloadFile(fileId), {
    staleTime: Infinity,
    enabled: !!fileId,
  });

export const useDeleteFile = () =>
  useMutation<unknown, AxiosError, { fileId: string }, unknown>(({ fileId }) => api.deleteFile(fileId));

export const useFileLink = (fileId: string) =>
  useQuery<string, AxiosError>(fileKeys.link(fileId), () => api.downloadFileLink(fileId), { enabled: !!fileId });

export const useDownloadFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>(
    ({ fileId }) => api.downloadFile(fileId),
    {
      onSuccess: (blob, variables) => {
        saveBlob(blob, variables.fileName);
      },
    },
  );

export const useDownloadPublicFile = () =>
  useMutation<Blob, AxiosError, { token: string; fileName: string }, unknown>(
    ({ token }) => api.downloadPublicFile(token),
    {
      onSuccess: (blob, variables) => {
        saveBlob(blob, variables.fileName);
      },
    },
  );

export const useDownloadSessionFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>(
    ({ fileId }) => api.downloadSessionFile(fileId),
    {
      onSuccess: (blob, variables) => {
        saveBlob(blob, variables.fileName);
      },
    },
  );

export const useDownloadActivityFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>(
    ({ fileId }) => api.downloadActivityFile(fileId),
    {
      onSuccess: (blob, variables) => {
        saveBlob(blob, variables.fileName);
      },
    },
  );

export const useActivityFile = (fileId: string) =>
  useQuery<DocumentFile, AxiosError>(fileKeys.activity(fileId), () => api.fetchActivityFile(fileId));

export const useActivityFileLink = (fileId: string) =>
  useQuery<string, AxiosError>(fileKeys.activityLink(fileId), () => api.fetchActivityFileLink(fileId), {
    enabled: !!fileId,
  });

export const useNoteFile = (fileId: string, noteId: string) =>
  useQuery<DocumentFile, AxiosError>(fileKeys.note(fileId), () => api.fetchNoteFile(fileId, noteId), {
    enabled: !!fileId,
  });

export const useNoteFileLink = (fileId: string) =>
  useQuery<string, AxiosError>(fileKeys.noteLink(fileId), () => api.fetchNoteFileLink(fileId), { enabled: !!fileId });

export const useDownloadNoteFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>(
    ({ fileId }) => api.downloadNoteFile(fileId),
    {
      onSuccess: (blob, variables) => {
        saveBlob(blob, variables.fileName);
      },
    },
  );

export const usePortfolioPublicFileLink = (fileId: string, resourceId: string) =>
  useQuery<string, AxiosError>(fileKeys.portfolioPublicLink(fileId, resourceId), () =>
    api.fetchPortfolioPublicFileLink(fileId, resourceId),
  );

export const useQuestionnaireManual = (fileId: string) =>
  useQuery<DocumentFile, AxiosError>(fileKeys.note(fileId), () => api.fetchQuestionnaireManual(fileId), {
    enabled: !!fileId,
  });

export const usePersonalNoteFile = (fileId: string) =>
  useQuery<DocumentFile, Error>(fileKeys.note(fileId), () => api.fetchPersonalNotesFile(fileId), {
    enabled: !!fileId,
  });

export const useDeletePersonalNotesFile = (recordId: string, searchParams?: IRecordsDocumentsParams) => {
  const queryClient = useQueryClient();
  const userId = getStoredUserId();
  const query = queryString.stringify(searchParams || defaultDocumentSearchParams);

  return useMutation<unknown, Error, { fileId: string }, unknown>(({ fileId }) => api.deletePersonalNotesFile(fileId), {
    onSuccess: () => {
      queryClient.invalidateQueries(recordKeys.documents(query, recordId, userId));
    },
  });
};
