import { $isNodeSelection, NodeKey } from 'lexical';
import cx from 'classnames';
import React from 'react';
import { ImagePreview, ImageResizer } from './components';
import s from './styles.module.scss';
import { useImage } from './hooks';

type ImageProps = {
  src: string;
  id: string;
  width: 'inherit' | number;
  height: 'inherit' | number;
  style?: string;
  nodeKey: NodeKey;
};

export const Image = ({ src, id, width, height, style, nodeKey }: ImageProps) => {
  const {
    imageRef,
    isFocused,
    selection,
    editor,
    isEditable,
    isPreviewOpen,
    onResizeEnd,
    onResizeStart,
    closePreview,
    openPreview,
  } = useImage(nodeKey);

  return (
    <>
      <img
        id={id}
        src={src}
        alt="{altText}"
        ref={imageRef}
        style={{
          height,
          width,
        }}
        draggable="false"
        className={cx(isFocused && isEditable && s.focused, !isEditable && s.preview)}
        onClick={!isEditable ? openPreview : undefined}
      />
      {$isNodeSelection(selection) && isFocused && isEditable && (
        <ImageResizer editor={editor} imageRef={imageRef} onResizeStart={onResizeStart} onResizeEnd={onResizeEnd} />
      )}
      {isPreviewOpen && <ImagePreview width={width} height={height} closePreview={closePreview} imageId={id} />}
    </>
  );
};
