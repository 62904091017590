import { selfHostedJitsiURL } from 'app/constants/app';
import { QIT_API_BASE_URL } from 'app/constants/env';

export const staticValues = {
  upgradeInsecureRequests: 'upgrade-insecure-requests',
  blockAllMixedContent: 'block-all-mixed-content',
  self: "'self'",
  unsafeInline: "'unsafe-inline'",
  none: "'none'",
  blob: 'blob:',
  data: 'data:',
  https: 'https://*',
};

export const services = {
  apiOrigin: new URL(QIT_API_BASE_URL).origin,
  google: {
    subdomain: 'https://*.google.com',
    analytics: 'https://www.google-analytics.com',
    tagManagerSubdomain: 'https://www.googletagmanager.com',
    staticSubdomain: 'https://*.gstatic.com',
    doubleClick: 'https://td.doubleclick.net',
    doubleClickSubdomain: 'https://*.g.doubleclick.net',
    apis: 'https://*.googleapis.com',
  },
  intercom: {
    subdomain: 'https://*.intercom.io',
    cdn: 'https://js.intercomcdn.com',
    nexus: 'wss://nexus-websocket-a.intercom.io',
  },
  facebook: {
    netSubdomain: 'https://*.facebook.net',
    comSubdomain: 'https://*.facebook.com',
  },
  clarity: {
    subdomain: 'https://*.clarity.ms',
  },
  linkedin: {
    subdomain: 'https://*.licdn.com',
    adsSubdomain: 'https://*.ads.linkedin.com',
  },
  videoMeeting: {
    domain: `https://${selfHostedJitsiURL}`,
  },
  officePreview: {
    domain: 'https://view.officeapps.live.com',
  },
  devCycle: {
    subdomain: 'https://*.devcycle.com',
  },
  realTime: {
    domain: 'https://realtime.ably.io',
  },
  sentry: {
    ingestSubdomain: 'https://*.ingest.sentry.io',
  },
  tiny: {
    tinymceSubdomain: 'https://*.tinymce.com',
    tinymceCloudSubdomain: 'https://*.tiny.cloud',
  },
  pdfjs: {
    dist: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/',
    unpkg: 'https://unpkg.com',
  },
};
