import { useInitialHtmlContent } from './hooks';

type InitialHtmlContentPluginProps = {
  initialHtmlContent: string;
};

export const InitialHtmlContentPlugin = ({ initialHtmlContent }: InitialHtmlContentPluginProps) => {
  useInitialHtmlContent(initialHtmlContent);

  return null;
};
