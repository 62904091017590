/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import cx from 'classnames';
import ArrowUndo from './static/arrow_undo.svg';
import ArrowRedo from './static/arrow_redo.svg';
import { useHistory } from './hooks';
import s from './styles.module.scss';

export const History = () => {
  const { canRedo, canUndo, undo, redo } = useHistory();

  return (
    <>
      <button className={cx('reset-button', s.button)} type="button" title="Undo" onClick={undo} disabled={!canUndo}>
        <ArrowUndo />
      </button>
      <button className={cx('reset-button', s.button)} type="button" title="Redo" onClick={redo} disabled={!canRedo}>
        <ArrowRedo />
      </button>
    </>
  );
};
