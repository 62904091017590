import React from 'react';
import { useTranslation } from 'react-i18next';
import s from './styles.module.scss';

type PlaceholderProps = {
  placeholder?: string;
};

export const Placeholder = ({ placeholder }: PlaceholderProps) => {
  const { t } = useTranslation();

  return <div className={s.placeholder}>{placeholder || t('addNotePlaceholder')}</div>;
};
