import { useState } from 'react';

export const useEditor = () => {
  const [floatingAnchorElement, setFloatingAnchorElement] = useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElement: HTMLDivElement) => {
    if (_floatingAnchorElement !== null) {
      setFloatingAnchorElement(_floatingAnchorElement);
    }
  };

  return { onRef, floatingAnchorElement };
};
