/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import cx from 'classnames';
import Bold from '../../../../static/bold.svg';
import Strikethrough from './static/strikethrough.svg';
import Underline from './static/underline.svg';
import Italic from '../../../../static/italic.svg';
import { useTextFormatting } from './hooks';
import s from './styles.module.scss';

export const TextFormatting = () => {
  const {
    isBold,
    isStrikethrough,
    isUnderline,
    isItalic,
    formatBold,
    formatItalic,
    formatUnderline,
    formatStrikethrough,
  } = useTextFormatting();

  return (
    <>
      <button
        className={cx('reset-button', s.button, isBold && s.buttonActive)}
        type="button"
        title="Bold"
        onClick={formatBold}
      >
        <Bold />
      </button>
      <button
        className={cx('reset-button', s.button, isStrikethrough && s.buttonActive)}
        type="button"
        title="Strikethrough"
        onClick={formatStrikethrough}
      >
        <Strikethrough />
      </button>
      <button
        className={cx('reset-button', s.button, isUnderline && s.buttonActive)}
        type="button"
        title="Underline"
        onClick={formatUnderline}
      >
        <Underline />
      </button>
      <button
        className={cx('reset-button', s.button, isItalic && s.buttonActive)}
        type="button"
        title="Italic"
        onClick={formatItalic}
      >
        <Italic />
      </button>
    </>
  );
};
