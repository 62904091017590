import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getTableCellNodeFromLexicalNode, TableCellNode } from '@lexical/table';
import { useClickOutside } from 'app/utils/hooks';
import { $getSelection, $isRangeSelection } from 'lexical';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

export const useTableContextMenu = (floatingAnchorElement: HTMLDivElement) => {
  const [editor] = useLexicalComposerContext();
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const contextMenuWrapperRef = useRef<HTMLDivElement>(null);
  const contextMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(null);

  const showMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setTableMenuCellNode(null);
  };

  const moveMenu = useCallback(() => {
    const menuWrapper = contextMenuWrapperRef.current;
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const { activeElement } = document;

    if (selection == null || menuWrapper == null) {
      setTableMenuCellNode(null);
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(selection.anchor.getNode());

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null);
        return;
      }

      const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey());

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null);
        return;
      }

      setTableMenuCellNode(tableCellNodeFromSelection);
    } else if (!activeElement) {
      setTableMenuCellNode(null);
    }
  }, [editor]);

  useClickOutside(contextMenuWrapperRef, closeMenu);

  useEffect(() =>
    editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        moveMenu();
      });
    }),
  );

  useEffect(() => {
    const menuWrapper = contextMenuWrapperRef.current;

    if (menuWrapper && tableCellNode) {
      const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey());

      if (tableCellNodeDOM != null) {
        const tableCellRect = tableCellNodeDOM.getBoundingClientRect();
        const menuWrapperRect = menuWrapper.getBoundingClientRect();
        const anchorRect = floatingAnchorElement.getBoundingClientRect();

        // 62 is the padding of the menu
        const padding = 62;
        const top = tableCellRect.top - anchorRect.top + padding;
        const left = tableCellRect.right - menuWrapperRect.width - anchorRect.left - 5;

        menuWrapper.style.opacity = '1';
        menuWrapper.style.transform = `translate(${left}px, ${top}px)`;
      } else {
        menuWrapper.style.opacity = '0';
        menuWrapper.style.transform = 'translate(-10000px, -10000px)';
      }
    }
  }, [contextMenuWrapperRef, floatingAnchorElement, tableCellNode, editor]);

  return {
    isMenuOpen,
    contextMenuRef,
    contextMenuWrapperRef,
    contextMenuButtonRef,
    tableCellNode,
    showMenu,
    closeMenu,
  };
};
