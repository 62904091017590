import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OUTDENT_CONTENT_COMMAND } from 'lexical';

export const useOutdent = () => {
  const [editor] = useLexicalComposerContext();

  const outdent = () => {
    editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
  };

  return {
    outdent,
  };
};
