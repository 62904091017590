import { $generateHtmlFromNodes } from '@lexical/html';
import { EditorState, LexicalEditor } from 'lexical';
import { useDebouncedCallback } from 'use-debounce';

const DELAY_IN_MILLISECONDS = 200;

const emptyHtml = '<p><br></p>';

export const useOnChange = (onChange: (htmlString: string) => void) => {
  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);

      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, 'text/html');

      // Select all elements in the DOM
      const elements = dom.querySelectorAll('*');

      // Iterate through each element
      elements.forEach((element) => {
        // Remove all classes from the element
        element.removeAttribute('class');
      });

      const htmlContent = dom.body.innerHTML;
      const isEmpty = htmlContent === emptyHtml;

      onChange(isEmpty ? '' : htmlContent);
    });
  };

  const debouncedHandleChange = useDebouncedCallback(handleChange, DELAY_IN_MILLISECONDS);

  return debouncedHandleChange;
};
