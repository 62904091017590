/* eslint-disable camelcase */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getTableNodeFromLexicalNodeOrThrow,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  HTMLTableElementWithWithTableSelectionState,
  TableCellNode,
  getTableObserverFromTableElement,
} from '@lexical/table';
import { $getRoot } from 'lexical';
import { useCallback, useEffect, useRef } from 'react';

export const useMenu = (closeMenu: () => void, tableCellNode: TableCellNode) => {
  const [editor] = useLexicalComposerContext();
  const menuRef = useRef<HTMLDivElement>(null);

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
        const tableElement = editor.getElementByKey(tableNode.getKey()) as HTMLTableElementWithWithTableSelectionState;

        if (!tableElement) {
          throw new Error('Expected to find tableElement in DOM');
        }

        const tableSelection = getTableObserverFromTableElement(tableElement);
        if (tableSelection !== null) {
          tableSelection.clearHighlight();
        }

        tableNode.markDirty();
      }

      const rootNode = $getRoot();
      rootNode.selectStart();
    });
  }, [editor, tableCellNode]);

  const insertRow = (below: boolean) => {
    editor.update(() => {
      $insertTableRow__EXPERIMENTAL(below);
      closeMenu();
    });
  };

  const insertColumn = (after: boolean) => {
    editor.update(() => {
      $insertTableColumn__EXPERIMENTAL(after);
      closeMenu();
    });
  };

  const deleteRow = () => {
    editor.update(() => {
      $deleteTableRow__EXPERIMENTAL();
      closeMenu();
    });
  };

  const deleteColumn = () => {
    editor.update(() => {
      $deleteTableColumn__EXPERIMENTAL();
      closeMenu();
    });
  };

  const deleteTable = () => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
      tableNode.remove();

      clearTableSelection();
      closeMenu();
    });
  };

  useEffect(() => {
    if (menuRef.current) {
      let transformY = '';
      let transformX = '';
      const { width, height, x, y } = menuRef.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      // Check if the menu is outside the viewport
      if (x + width > innerWidth) {
        transformX = `translateX(-${width - 20}px)`;
      }
      if (y + height > innerHeight) {
        transformY = `translateY(-${height - 20}px)`;
      }

      // Combine both transformations
      const combinedTransform = `${transformX} ${transformY}`.trim();
      menuRef.current.style.transform = combinedTransform;
    }
  }, []);

  return {
    insertRow,
    insertColumn,
    deleteRow,
    deleteColumn,
    deleteTable,
    menuRef,
  };
};
