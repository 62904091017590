import { $createListItemNode, ListItemNode } from '@lexical/list';
import { COMMAND_PRIORITY_EDITOR, DOMConversionMap, EditorConfig } from 'lexical';

const addStyle = () => {
  ListItemNode.prototype.__style = '';

  ListItemNode.prototype.setStyle = function (style: string): ListItemNode {
    const self = this.getWritable();
    self.__style = style;
    return self;
  };
};

const extendCreateDOM = (originalCreateDOM: (config: EditorConfig) => HTMLElement) => {
  // Extending createDOM method of ListItemNode to be able to use inline styles
  ListItemNode.prototype.createDOM = function (config: EditorConfig): HTMLElement {
    const element: HTMLElement = originalCreateDOM.call(this, config);
    const { __style } = this;
    if (__style) {
      element.setAttribute('style', __style);
    }
    return element;
  };
};

const overrideImportDOM = () => {
  // Overriding importNode method of ListItemNode to be able to use inline styles
  ListItemNode.importDOM = (): DOMConversionMap | null => ({
    li: () => ({
      conversion: (node: HTMLElement) => {
        const itemNode = $createListItemNode();
        itemNode.setStyle(node.style.cssText);

        return { node: itemNode };
      },
      priority: COMMAND_PRIORITY_EDITOR,
    }),
  });
};

export const extendListItemNode = () => {
  const originalCreateDOM = ListItemNode.prototype.createDOM;

  addStyle();
  overrideImportDOM();
  extendCreateDOM(originalCreateDOM);
};
