import {
  $getSelection,
  $isLineBreakNode,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { getSelectedNode, sanitizeUrl } from '../../../utils';
import { defaultLinkUrl } from '../../../constants';
import { setFloatingLinkEditorPosition } from '../utils';

export const useFloatingLink = (floatingAnchorElement: HTMLDivElement) => {
  const [editor] = useLexicalComposerContext();

  const linkEditorRef = useRef<HTMLDivElement | null>(null);

  // isLink is true if the current selection is a link
  const [isLink, setIsLink] = useState(false);
  // linkUrl is the actual URL of the link
  const [linkUrl, setLinkUrl] = useState('');
  // isEditMode is true if the user is currently editing the link
  const [isEditMode, setIsLinkEditMode] = useState(true);
  // editedLinkUrl is the URL that the user is currently editing
  const [editedLinkUrl, setEditedLinkUrl] = useState(defaultLinkUrl);

  // Update floating editor state
  const $updateFloatingEditorState = () => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const focusNode = getSelectedNode(selection);
      // Find the link node that the focus node is in
      const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);

      // If there is no link node, then it is not a link
      if (!focusLinkNode) {
        setIsLink(false);
        return;
      }

      const badNode = selection
        .getNodes()
        .filter((node) => !$isLineBreakNode(node))
        .find((node) => {
          const linkNode = $findMatchingParent(node, $isLinkNode);
          return (focusLinkNode && !focusLinkNode.is(linkNode)) || (linkNode && !linkNode.is(focusLinkNode));
        });

      if (!badNode) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  };

  // Register listeners for editor state
  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateFloatingEditorState();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            $updateFloatingEditorState();
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
      ),
    [editor],
  );

  // POSITIONING

  const $updateFloatingEditorPosition = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const linkParent = $findMatchingParent(node, $isLinkNode);

      if (linkParent) {
        setLinkUrl(linkParent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
      if (isEditMode) {
        setEditedLinkUrl(linkUrl);
      }
    }
    const editorElem = linkEditorRef.current;
    const nativeSelection = window.getSelection();
    const { activeElement } = document;

    if (editorElem === null) return;

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRect: DOMRect | undefined = nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
      if (domRect) {
        domRect.y += 40;
        setFloatingLinkEditorPosition(domRect, editorElem, floatingAnchorElement);
      }
    } else if (!activeElement || activeElement.id !== 'link-input') {
      if (rootElement !== null) {
        setFloatingLinkEditorPosition(null, editorElem, floatingAnchorElement);
      }
      setIsLinkEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [floatingAnchorElement, editor, setIsLinkEditMode, isEditMode, linkUrl]);

  useEffect(() => {
    const scrollerElem = floatingAnchorElement.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        $updateFloatingEditorPosition();
      });
    };

    window.addEventListener('resize', update);

    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);

      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [floatingAnchorElement.parentElement, editor, $updateFloatingEditorPosition]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateFloatingEditorPosition();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            $updateFloatingEditorPosition();
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          KEY_ESCAPE_COMMAND,
          () => {
            if (isLink) {
              setIsLink(false);
              return true;
            }
            return false;
          },
          COMMAND_PRIORITY_HIGH,
        ),
      ),
    [editor, $updateFloatingEditorPosition, setIsLink, isLink],
  );

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateFloatingEditorPosition();
    });
  }, [editor, $updateFloatingEditorPosition]);

  const handleSubmit = () => {
    if (linkUrl) {
      const url = sanitizeUrl(editedLinkUrl);
      editor.dispatchCommand(
        TOGGLE_LINK_COMMAND,
        url
          ? {
              url,
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : null,
      );
    }

    setEditedLinkUrl(defaultLinkUrl);
    setIsLinkEditMode(false);
  };

  const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      setIsLinkEditMode(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedLinkUrl(event.target.value);
  };

  const showEditMode = () => {
    setEditedLinkUrl(linkUrl);
    setIsLinkEditMode(true);
  };

  const closeEditMode = () => {
    setIsLinkEditMode(false);
  };

  const deleteLink = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
  };

  return {
    values: {
      isLink,
      linkUrl,
      isEditMode,
      editedLinkUrl,
      linkEditorRef,
    },
    handlers: {
      deleteLink,
      handleSubmit,
      showEditMode,
      closeEditMode,
      handleInputChange,
      handleInputKeydown,
    },
  };
};
