import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INDENT_CONTENT_COMMAND } from 'lexical';

export const useIndent = () => {
  const [editor] = useLexicalComposerContext();

  const indent = () => {
    editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
  };

  return {
    indent,
  };
};
