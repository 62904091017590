import { getScrollParent } from 'app/components/core/editor/utils';
import { useEffect, useRef, useState } from 'react';

const topMargin = 30;
const leftMargin = -4;

const calculateDropdownPosition = (wrapper: HTMLDivElement, dropdown: HTMLDivElement) => {
  const { x: wrapperX, y: wrapperY } = wrapper.getBoundingClientRect();

  dropdown.style.left = `${wrapperX + leftMargin}px`;
  dropdown.style.top = `${wrapperY + topMargin}px`;
};

export const useExtra = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  // useClickOutside(dropdownRef, closeDropdown);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const dropdown = dropdownRef.current;

    if (!wrapper || !dropdown) return;

    const scrollContainer = getScrollParent(dropdown);
    const handleDropdownPositionUpdate = () => {
      calculateDropdownPosition(wrapper, dropdown);
    };

    if (scrollContainer && isDropdownOpen) {
      scrollContainer.addEventListener('scroll', handleDropdownPositionUpdate);
    }

    return () => {
      if (scrollContainer && isDropdownOpen)
        scrollContainer.removeEventListener('scroll', handleDropdownPositionUpdate);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const dropdown = dropdownRef.current;

    if (!wrapper || !dropdown || !isDropdownOpen) return;

    calculateDropdownPosition(wrapper, dropdown);
  }, [isDropdownOpen]);

  return {
    wrapperRef,
    dropdownRef,
    isDropdownOpen,
    openDropdown,
    closeDropdown,
  };
};
