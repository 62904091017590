/* eslint-disable no-bitwise */
import * as React from 'react';
import type { LexicalEditor } from 'lexical';
import { useImageResizer } from './hooks';
import './styles.css';

type ImageResizerProps = {
  editor: LexicalEditor;
  imageRef: { current: null | HTMLElement };
  onResizeEnd: (width: 'inherit' | number, height: 'inherit' | number) => void;
  onResizeStart: () => void;
};

export const ImageResizer = ({ onResizeStart, onResizeEnd, imageRef, editor }: ImageResizerProps) => {
  const { handlePointerDown, controlWrapperRef, Direction } = useImageResizer(
    editor,
    onResizeEnd,
    onResizeStart,
    imageRef,
  );

  return (
    <div ref={controlWrapperRef}>
      <div
        className="image-resizer image-resizer-ne"
        onPointerDown={(event) => {
          handlePointerDown(event, Direction.north | Direction.east);
        }}
      />
      <div
        className="image-resizer image-resizer-se"
        onPointerDown={(event) => {
          handlePointerDown(event, Direction.south | Direction.east);
        }}
      />
      <div
        className="image-resizer image-resizer-sw"
        onPointerDown={(event) => {
          handlePointerDown(event, Direction.south | Direction.west);
        }}
      />
      <div
        className="image-resizer image-resizer-nw"
        onPointerDown={(event) => {
          handlePointerDown(event, Direction.north | Direction.west);
        }}
      />
    </div>
  );
};
