/* eslint-disable no-underscore-dangle */
import { InitialConfigType } from '@lexical/react/LexicalComposer';
import { EditorThemeClasses } from 'lexical';
import { ListItemNode, ListNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { HeadingNode } from '@lexical/rich-text';
import { onError } from '../utils';
import { DynamicValueNode, ImageNode } from '../nodes';

const editorNamespace = 'QITEditor';

const theme: EditorThemeClasses = {
  text: {
    underline: 'editorTextUnderline',
    italic: 'editorTextItalic',
    strikethrough: 'editorTextStrikeThrough',
    bold: 'editorTextBold',
    underlineStrikethrough: 'editorTextUnderlineStrikeThrough',
  },
  heading: {
    h1: 'editorHeading1',
    h2: 'editorHeading2',
    h3: 'editorHeading3',
    h4: 'editorHeading4',
    h5: 'editorHeading5',
    h6: 'editorHeading6',
  },
  paragraph: 'editorParagraph',
  list: {
    ul: 'editorUnorderedList',
    ol: 'editorOrderedList',
    nested: {
      listitem: 'editorNestedListItem',
    },
    ulDepth: [
      'editorUnorderedListDepth1',
      'editorUnorderedListDepth2',
      'editorUnorderedListDepth3',
      'editorUnorderedListDepth4',
      'editorUnorderedListDepth5',
    ],
  },
  link: 'editorLink',
  image: 'editorImage',
  table: 'editorTable',
  tableCell: 'editorTableCell',
  tableCellResizer: 'editorTableCellResizer',
  tableResizeRuler: 'editorTableCellResizeRuler',
  // The classnames are inconsistent with the actual classnames above
  // to keep it inline with old (TINY) dynamic values which are stored in DB
  // template styles
  dynamicValue: 'variable',
  // document styles
  dynamicValueWithValue: 'replaced-variable',
  // document styles without value
  dynamicValueEmpty: 'empty-variable',
};

export const initialConfig: InitialConfigType = {
  namespace: editorNamespace,
  onError,
  theme,
  nodes: [
    ListNode,
    ListItemNode,
    ImageNode,
    LinkNode,
    HeadingNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    DynamicValueNode,
  ],
};

export const defaultLinkUrl = 'https://';
