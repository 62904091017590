import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { useEffect } from 'react';
import { $wrapNodeInElement } from '@lexical/utils';
import { $createDynamicValueNode, DynamicValueNode } from '../../../nodes';
import { InsertDynamicValuePayload } from '../../../types';
import { INSERT_DYNAMIC_VALUE_COMMAND } from '../../../commands';

export const useDynamicValue = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([DynamicValueNode])) {
      throw new Error('DynamicValuePlugin: DynamicValueNode not registered on editor');
    }

    return editor.registerCommand<InsertDynamicValuePayload>(
      INSERT_DYNAMIC_VALUE_COMMAND,
      (payload) => {
        const dynamicValueNode = $createDynamicValueNode(payload);

        $insertNodes([dynamicValueNode]);

        if ($isRootOrShadowRoot(dynamicValueNode.getParentOrThrow())) {
          $wrapNodeInElement(dynamicValueNode, $createParagraphNode).selectEnd();
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);
};
