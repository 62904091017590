import React from 'react';
import Select from 'react-select';
import s from './styles.module.scss';
import { useDynamicValue } from './hooks';
import { Variable } from './types';
import { SelectControl } from './components';

const styles = {
  menuPortal: (provided) => ({
    ...provided,
    width: 200,
    'z-index': '9999',
  }),
};

type DynamicValueProps = {
  variables: Variable[];
  isDocumentBuilder?: boolean;
};

export const DynamicValue = ({ variables, isDocumentBuilder }: DynamicValueProps) => {
  const { options, insert } = useDynamicValue(variables, isDocumentBuilder);

  return (
    <Select
      styles={styles}
      isSearchable={false}
      options={options}
      className={s.select}
      onChange={insert}
      menuPortalTarget={document.body}
      components={{ Control: SelectControl }}
    />
  );
};
