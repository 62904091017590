import React, { useEffect } from 'react';

export const useClickOutside = (ref: React.RefObject<HTMLDivElement>, closeContextMenu: () => void) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }
      closeContextMenu();
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};
