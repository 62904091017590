import { $isTextNode, DOMConversion, DOMConversionMap, TextNode } from 'lexical';

const patchStyleConversion =
  (originalDOMConverter?: (node: HTMLElement) => DOMConversion | null) => (node: HTMLElement) => {
    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }
    const originalOutput = original.conversion(node);

    if (!originalOutput) {
      return originalOutput;
    }

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? ((x) => x);
        const result = originalForChild(lexicalNode, parent);

        if ($isTextNode(result)) {
          const { cssText } = node.style;

          if (cssText.length) {
            return result.setStyle(cssText);
          }
        }
        return result;
      },
    };
  };

const overrideImportDOM = (originalImportDOM: () => DOMConversionMap | null) => {
  const importers = originalImportDOM();

  // override importers
  TextNode.importDOM = () => ({
    ...importers,
    em: () => ({
      conversion: patchStyleConversion(importers?.em),
      priority: 1,
    }),
    span: () => ({
      conversion: patchStyleConversion(importers?.span),
      priority: 1,
    }),
    strong: () => ({
      conversion: patchStyleConversion(importers?.strong),
      priority: 1,
    }),
    b: () => ({
      conversion: patchStyleConversion(importers?.b),
      priority: 1,
    }),
    u: () => ({
      conversion: patchStyleConversion(importers?.u),
      priority: 1,
    }),
  });
};

export const extendTextNode = () => {
  const originalImportDOM = TextNode.importDOM;

  overrideImportDOM(originalImportDOM);
};
