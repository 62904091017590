import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { defaultLinkUrl } from 'app/components/core/editor/constants';
import { getSelectedNode, sanitizeUrl } from 'app/components/core/editor/utils';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_CRITICAL, SELECTION_CHANGE_COMMAND } from 'lexical';
import { useCallback, useEffect, useState } from 'react';

export const useLink = () => {
  const [isLink, setIsLink] = useState(false);
  const [editor] = useLexicalComposerContext();

  const insertLink = () => {
    if (!isLink) {
      // Insert "empty" link to start editing
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(defaultLinkUrl));
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  };

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, []);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            $updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateToolbar();
          });
        }),
      ),
    [editor, $updateToolbar],
  );

  return {
    isLink,
    insertLink,
  };
};
