import React from 'react';
import { Helmet } from 'react-helmet';
import { useContentSecurityPolicy } from './hooks';

export const ContentSecurityPolicy = () => {
  const { content } = useContentSecurityPolicy();

  return (
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content={content} />
    </Helmet>
  );
};
