import React from 'react';
import cx from 'classnames';
import IndentIcon from './static/indent.svg';
import { useIndent } from './hooks';
import s from './styles.module.scss';

export const Indent = () => {
  const { indent } = useIndent();

  return (
    <button
      className={cx('reset-button', s.button)}
      type="button"
      title="Indent"
      aria-label="Insert table"
      onClick={indent}
    >
      <IndentIcon />
    </button>
  );
};
