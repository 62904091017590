import { useEffect } from 'react';
import { getScrollParent } from 'app/components/core/editor/utils';
import { calculatePosition } from '../utils';

type UseDropdownsPositionParams = {
  isFontColorDropdownOpen: boolean;
  fontColorDropdownRef: React.RefObject<HTMLDivElement>;
  fontColorButtonRef: React.RefObject<HTMLButtonElement>;
  isFontBackgroundColorDropdownOpen: boolean;
  fontBackgroundColorDropdownRef: React.RefObject<HTMLDivElement>;
  fontBackgroundColorButtonRef: React.RefObject<HTMLButtonElement>;
  isFloatingEditor?: boolean;
};

export const useDropdownsPosition = ({
  isFontColorDropdownOpen,
  fontColorDropdownRef,
  fontColorButtonRef,
  isFontBackgroundColorDropdownOpen,
  fontBackgroundColorDropdownRef,
  fontBackgroundColorButtonRef,
  isFloatingEditor,
}: UseDropdownsPositionParams) => {
  useEffect(() => {
    if (isFloatingEditor) return;

    const scrollContainer = getScrollParent(fontColorDropdownRef.current);
    const handleDropdownPositionUpdate = () => {
      calculatePosition(fontColorButtonRef, fontColorDropdownRef);
    };

    if (isFontColorDropdownOpen && scrollContainer) {
      scrollContainer.addEventListener('scroll', handleDropdownPositionUpdate);
    }

    return () => {
      if (isFontColorDropdownOpen && scrollContainer && !isFloatingEditor)
        scrollContainer.removeEventListener('scroll', handleDropdownPositionUpdate);
    };
  }, [isFontColorDropdownOpen, fontColorDropdownRef, fontColorButtonRef, isFloatingEditor]);

  useEffect(() => {
    if (isFloatingEditor) return;

    const scrollContainer = getScrollParent(fontBackgroundColorDropdownRef.current);
    const handleDropdownPositionUpdate = () => {
      calculatePosition(fontBackgroundColorButtonRef, fontBackgroundColorDropdownRef);
    };

    if (isFontBackgroundColorDropdownOpen && scrollContainer) {
      scrollContainer.addEventListener('scroll', handleDropdownPositionUpdate);
    }

    return () => {
      if (isFontColorDropdownOpen && scrollContainer && !isFloatingEditor)
        scrollContainer.removeEventListener('scroll', handleDropdownPositionUpdate);
    };
  }, [isFontBackgroundColorDropdownOpen, fontBackgroundColorDropdownRef, fontBackgroundColorButtonRef]);

  useEffect(() => {
    if (isFontColorDropdownOpen && !isFloatingEditor) {
      calculatePosition(fontColorButtonRef, fontColorDropdownRef);
    }
  }, [fontColorDropdownRef, fontColorButtonRef, isFontColorDropdownOpen, isFloatingEditor]);

  useEffect(() => {
    if (isFontBackgroundColorDropdownOpen && !isFloatingEditor) {
      calculatePosition(fontBackgroundColorButtonRef, fontBackgroundColorDropdownRef);
    }
  }, [fontColorDropdownRef, fontColorButtonRef, isFontBackgroundColorDropdownOpen, isFloatingEditor]);
};
