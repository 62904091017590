/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { PUIButtonIcon } from 'ui';
import { ChromePicker } from 'react-color';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import TextBackgroundIcon from './static/text_background.svg';
import s from './styles.module.scss';
import { useDropdownsPosition, useFontBackgroundColor, useFontColor } from './hooks';
import { DefaultColors } from './components';

type FontColorsProps = {
  setIsActive?: React.Dispatch<React.SetStateAction<boolean>>;
  isFloatingEditor?: boolean;
};

export const FontColors = ({ setIsActive, isFloatingEditor }: FontColorsProps) => {
  const { t } = useTranslation();
  const {
    values: { fontColor, isFontColorDropdownOpen },
    handlers: { openFontColorDropdown, closeFontColorDropdown, changeFontColor, changeDefaultColor },
    refs: { fontColorButtonRef, fontColorDropdownRef },
  } = useFontColor();
  const {
    values: { fontBackgroundColor, isFontBackgroundColorDropdownOpen },
    handlers: {
      openFontBackgroundColorDropdown,
      closeFontBackgroundColorDropdown,
      changeFontBackgroundColor,
      removeBackgroundColor,
      changeDefaultBackgroundColor,
    },
    refs: { fontBackgroundColorButtonRef, fontBackgroundColorDropdownRef },
  } = useFontBackgroundColor();
  useDropdownsPosition({
    isFontColorDropdownOpen,
    fontColorDropdownRef,
    fontColorButtonRef,
    isFontBackgroundColorDropdownOpen,
    fontBackgroundColorDropdownRef,
    fontBackgroundColorButtonRef,
    isFloatingEditor,
  });

  useEffect(() => {
    // set toolbar active state
    if (setIsActive) setIsActive(isFontBackgroundColorDropdownOpen || isFontColorDropdownOpen);

    return () => {
      if (setIsActive) setIsActive(false);
    };
  }, [isFontBackgroundColorDropdownOpen, isFontColorDropdownOpen]);

  return (
    <>
      <div className={cx(s.wrapper, isFloatingEditor && s.wrapperFloating)}>
        <button
          type="button"
          ref={fontColorButtonRef}
          title="Text color"
          className={cx('reset-button', s.button)}
          onClick={isFontColorDropdownOpen ? closeFontColorDropdown : openFontColorDropdown}
        >
          <div className={s.buttonContentWrapper}>A</div>
          <div className={s.color} style={{ backgroundColor: fontColor }} />
        </button>
        {isFontColorDropdownOpen && (
          <div className={s.dropdown} ref={fontColorDropdownRef}>
            <ChromePicker className={s.picker} onChange={changeFontColor} color={fontColor} />
            <DefaultColors onChange={changeDefaultColor} />
          </div>
        )}
      </div>
      <div className={cx(s.wrapper, isFloatingEditor && s.wrapperFloating)}>
        <button
          title="Text background"
          type="button"
          className={cx('reset-button', s.button, s.buttonBackground)}
          ref={fontBackgroundColorButtonRef}
          onClick={
            isFontBackgroundColorDropdownOpen ? closeFontBackgroundColorDropdown : openFontBackgroundColorDropdown
          }
        >
          <div className={s.buttonContentWrapper}>
            <TextBackgroundIcon className={s.icon} />
          </div>
          <div className={s.color} style={{ backgroundColor: fontBackgroundColor }} />
        </button>
        {isFontBackgroundColorDropdownOpen && (
          <div className={s.dropdown} ref={fontBackgroundColorDropdownRef}>
            <ChromePicker
              disableAlpha
              className={s.picker}
              onChange={changeFontBackgroundColor}
              color={fontBackgroundColor}
            />
            <DefaultColors onChange={changeDefaultBackgroundColor} />
            <PUIButtonIcon icon="remove" type="button" className={s.removeBackground} onClick={removeBackgroundColor}>
              {t('removeBackground')}
            </PUIButtonIcon>
          </div>
        )}
      </div>
    </>
  );
};
