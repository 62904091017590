import { RefObject } from 'react';

const dropDownPadding = 4;

export const calculatePosition = (buttonRef: RefObject<HTMLButtonElement>, dropdownRef: RefObject<HTMLDivElement>) => {
  const button = buttonRef.current;
  const dropdown = dropdownRef.current;

  if (button && dropdown) {
    const { top, left } = button.getBoundingClientRect();
    dropdown.style.top = `${top + button.offsetHeight + dropDownPadding}px`;
    dropdown.style.left = `${Math.min(left, window.innerWidth - dropdown.offsetWidth - 20)}px`;
  }
};
