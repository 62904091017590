const VERTICAL_GAP = 50;
const HORIZONTAL_OFFSET = 5;

export const setTextFormatterPosition = (
  targetRect: DOMRect,
  floatingElem: HTMLDivElement,
  anchorElem: HTMLDivElement,
) => {
   const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.transform = 'translate(-10000px, -10000px)';
    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  let top = targetRect.top - floatingElemRect.height + VERTICAL_GAP;
  let left = targetRect.left - HORIZONTAL_OFFSET;

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - HORIZONTAL_OFFSET;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
};
