import React from 'react';
import { useFloatingLink } from './hooks';
import { EditLinkForm, LinkPreview } from './components';
import s from './styles.module.scss';

type FloatingLinkEditorPluginProps = {
  floatingAnchorElement: HTMLDivElement;
};

export const FloatingLinkEditorPlugin = ({ floatingAnchorElement }: FloatingLinkEditorPluginProps) => {
  const {
    values: { isEditMode, isLink, linkEditorRef, editedLinkUrl, linkUrl },
    handlers: { handleSubmit, handleInputKeydown, handleInputChange, closeEditMode, deleteLink, showEditMode },
  } = useFloatingLink(floatingAnchorElement);

  // If no link is selected, do nothing
  if (!isLink) return null;

  return (
    <div ref={linkEditorRef} className={s.editor}>
      {isEditMode ? (
        <EditLinkForm
          value={editedLinkUrl}
          closeForm={closeEditMode}
          handleSubmit={handleSubmit}
          onChange={handleInputChange}
          onKeyDown={handleInputKeydown}
        />
      ) : (
        <LinkPreview linkUrl={linkUrl} deleteLink={deleteLink} showEditMode={showEditMode} />
      )}
    </div>
  );
};
