/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactPortal, useMemo } from 'react';
import { createPortal } from 'react-dom';
import useLexicalEditable from '@lexical/react/useLexicalEditable';

import s from './styles.module.scss';
import { useTableResize } from './hooks';

const TableCellResizer = () => {
  const { resizerRef, resizerStyles, activeCell, isMouseDown, toggleResize } = useTableResize();

  return (
    <div ref={resizerRef}>
      {activeCell && !isMouseDown && (
        <>
          <div className={s.resizer} style={resizerStyles.left || undefined} onMouseDown={toggleResize('left')} />
          <div className={s.resizer} style={resizerStyles.bottom || undefined} onMouseDown={toggleResize('bottom')} />
        </>
      )}
    </div>
  );
};

export default function TableCellResizerPlugin(): null | ReactPortal {
  const isEditable = useLexicalEditable();

  return useMemo(() => (isEditable ? createPortal(<TableCellResizer />, document.body) : null), [isEditable]);
}
