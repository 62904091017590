import React from 'react';
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';
import s from './styles.module.scss';

type ContentEditableProps = {
  onRef: (_editorRef: HTMLDivElement) => void;
  dataCy?: string;
};

export const ContentEditable = ({ onRef, dataCy }: ContentEditableProps) => (
  <div ref={onRef} data-cy={dataCy}>
    <LexicalContentEditable id="editor" contentEditable={false} className={s.editor} />
  </div>
);
