import './app/i18n';
import 'app/styles/global.module.scss';
import 'core-js/stable';

import 'react-toastify/dist/ReactToastify.css';
import 'regenerator-runtime/runtime';

import '@panenco/ui/lib/styles.css';

import React, { Component, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';

import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntercomProvider } from 'react-use-intercom';
import { appTheme, themeMUI } from 'ui';
import * as Sentry from '@sentry/react';

import Qit from 'app/containers';
import { ViewportProvider } from 'app/utils/context/viewport-provider';
import { TooltipProvider } from 'app/utils/context/tooltip-provider';
import Loader from 'app/components/core/preloader';
import IdleTimerComponent from 'app/containers/idle-timer';
import GlobalErrorBoundary from 'app/utils/error-boundaries/global';
import { captureException, init as initializeErrorTrackingService } from 'app/utils/services/error-tracking';
import { init as initializeReactPixel } from 'app/utils/services/pixel';

import { QIT_INTERCOM_API_KEY } from 'app/constants/env';
import UnsupportedBrowserPage from 'app/components/unsupportedBrowser';
import { queryClient } from 'data/react-query/queryClient';
import history from './app/history';
import Maintenance from './app/components/shared/maintenance';
import { TitleProvider } from './app/components/core/title';
import getIEVersion from './app/utils/getIEVersion';
import { intercomInitializeDelay, isMaintained } from './app/constants/app';

const rootElement = document.getElementById('root');

const iEVersion = getIEVersion();

initializeErrorTrackingService();
initializeReactPixel();

class App extends Component {
  componentDidCatch(error, errorInfo) {
    captureException(error, errorInfo);
  }

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <IntercomProvider appId={QIT_INTERCOM_API_KEY} autoBoot={false} initializeDelay={intercomInitializeDelay}>
              <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <ThemeProvider theme={appTheme}>
                  <MuiThemeProvider theme={themeMUI}>
                    {isMaintained ? (
                      <Maintenance />
                    ) : (
                      <GlobalErrorBoundary history={history}>
                        <ViewportProvider>
                          <TooltipProvider theme={appTheme}>
                            <TitleProvider>
                              <Qit />
                              <IdleTimerComponent />
                            </TitleProvider>
                          </TooltipProvider>
                        </ViewportProvider>
                      </GlobalErrorBoundary>
                    )}
                  </MuiThemeProvider>
                </ThemeProvider>
              </QueryParamProvider>
            </IntercomProvider>
          </BrowserRouter>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    );
  }
}

const root = createRoot(rootElement, {
  // Callback called when an error is thrown and not caught by an ErrorBoundary.
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack);
  }),
  // Callback called when React catches an error in an ErrorBoundary.
  onCaughtError: Sentry.reactErrorHandler(),
  // Callback called when React automatically recovers from errors.
  onRecoverableError: Sentry.reactErrorHandler(),
});

if (iEVersion && iEVersion < 11) {
  root.render(<UnsupportedBrowserPage />);
} else {
  root.render(<App />);
}
