import { useEffect } from 'react';
import { defaultLinkUrl } from '../constants';

export const useBlockEmptyLinkNavigation = () => {
  useEffect(() => {
    const handleLinkClick = (event) => {
      const target = event.target.closest('a');
      if (target && target.getAttribute('href') === defaultLinkUrl) {
        event.preventDefault();
      }
    };

    document.addEventListener('click', handleLinkClick);

    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, []);
};
