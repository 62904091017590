import { ReactNode, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, PASTE_COMMAND } from 'lexical';
import { toast } from 'ui';
import { getPrivateFile } from 'data';
import { useUploadPublicFile } from 'data/hooks';
import { INSERT_IMAGE_COMMAND } from '../../commands';
import { useTranslation } from 'react-i18next';

export const PasteImagePlugin = (): ReactNode => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const { mutate: upload } = useUploadPublicFile();

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (payload: ClipboardEvent) => {
        const { clipboardData } = payload;
        const fileToPaste = clipboardData?.files[0];

        if (fileToPaste && fileToPaste.type.includes('image')) {
          const formData = new FormData();
          formData.append('file', fileToPaste);

          upload(
            { formData },
            {
              onSuccess: (res) => {
                const src = getPrivateFile(res.token);
                if (!src) return;

                editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src, id: res.id });
              },
              onError: () => {
                toast.error(t('upload_failed'));
              },
            },
          );
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_LOW,
    );
  }, [editor]);

  return null;
};
