import { FocusEvent } from 'react';

const preventDefaultFunction = (e: Event) => e.preventDefault();

// custom onFocus to prevent scroll on input with type number
export const onFocus = (e: FocusEvent<HTMLInputElement>): void =>
  e.target.addEventListener('wheel', (event) => preventDefaultFunction(event), { passive: false });

export const onBlur = (e: FocusEvent<HTMLInputElement>): void =>
  e.target.removeEventListener('wheel', (event) => preventDefaultFunction(event));
