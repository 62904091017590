/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import cx from 'classnames';
import { useLists } from './hooks';
import OrderedList from './static/ordered_list.svg';
import UnorderedList from './static/unordered_list.svg';
import s from './styles.module.scss';

export const Lists = () => {
  const { isOrderedList, isUnorderedList, formatOrderedList, formatUnorderedList } = useLists();

  return (
    <>
      <button
        className={cx('reset-button', s.button, isOrderedList && s.buttonActive)}
        type="button"
        title="Ordered list"
        onClick={formatOrderedList}
      >
        <OrderedList />
      </button>
      <button
        className={cx('reset-button', s.button, isUnorderedList && s.buttonActive)}
        type="button"
        title="Unordered list"
        onClick={formatUnorderedList}
      >
        <UnorderedList />
      </button>
    </>
  );
};
