import FormField from 'app/components/core/form/field';
import { getCreateTableSchema } from 'data/utils/validation';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PUIButton, PUITextInput, PopupPUI } from 'ui';
import { InitialValues, MAX_COLUMNS, MAX_ROWS, MIN_COLUMNS, MIN_ROWS } from '../../hooks';
import s from './styles.module.scss';

type CreateTablePopupProps = {
  closePopup: () => void;
  createTable: (values: InitialValues) => void;
  initialValues: InitialValues;
};

export const CreateTablePopup = ({ closePopup, createTable, initialValues }: CreateTablePopupProps) => {
  const { t } = useTranslation();

  return (
    <PopupPUI onHide={closePopup}>
      <PopupPUI.Header>
        <PopupPUI.Title>{t('createTable')}</PopupPUI.Title>
      </PopupPUI.Header>
      <PopupPUI.Body>
        <Formik<InitialValues>
          onSubmit={createTable}
          initialValues={initialValues}
          validationSchema={() => getCreateTableSchema(MAX_COLUMNS, MAX_ROWS)}
        >
          {({ isValid }) => (
            <Form>
              <Field
                id="columns"
                name="columns"
                component={FormField}
                inputComponent={PUITextInput}
                labelText={t('columnsCount')}
                type="number"
                min={MIN_COLUMNS}
                MAX_COLUMNS={MAX_COLUMNS}
              />
              <Field
                id="rows"
                name="rows"
                component={FormField}
                inputComponent={PUITextInput}
                labelText={t('rowsCount')}
                type="number"
                min={MIN_ROWS}
                max={MAX_ROWS}
              />
              <div className={s.buttons}>
                <PUIButton onClick={closePopup} className={s.button}>
                  {t('cancel')}
                </PUIButton>
                <PUIButton
                  variant="contained"
                  className={s.button}
                  disabled={!isValid}
                  type="submit"
                  onClick={(e) => e.stopPropagation()}
                >
                  {t('create')}
                </PUIButton>
              </div>
            </Form>
          )}
        </Formik>
      </PopupPUI.Body>
    </PopupPUI>
  );
};
