export const defaultColors = [
  '#bfedd2',
  '#fbeeb8',
  '#f8cac6',
  '#eccafa',
  '#c2e0f4',
  '#2dc26b',
  '#f1c40f',
  '#e03e2d',
  '#b96ad9',
  '#3598db',
  '#169179',
  '#e67e23',
  '#ba372a',
  '#843fa1',
  '#236fa1',
  '#ecf0f1',
  '#ced4d9',
  '#95a5a6',
  '#7e8c8d',
  '#34495e',
]