export const getScrollParent = (node: HTMLElement | null) => {
  if (node == null) {
    return null;
  }

  const { overflowY } = window.getComputedStyle(node);
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentNode as HTMLElement);
};
