import React, { ReactElement } from 'react';
import { TextInput, TextInputProps } from '@panenco/ui';
import cx from 'classnames';
import { useFastTextField } from 'ui/utils/hooks/useFastTextField';

import { onBlur, onFocus } from './utils';
import s from './styles.module.scss';

export const PUITextInput = ({ className, ...props }: TextInputProps): ReactElement => (
  <TextInput
    className={cx(props.iconAfter && s.icon, s.root, className)}
    {...props}
    {...(props.type === 'number' && { onFocus })}
    {...(props.type === 'number' && { onBlur })}
  />
);

export const FastTextInput = ({ ...props }: TextInputProps): ReactElement => {
  const [value, handleOnChange] = useFastTextField(props);

  return <PUITextInput {...props} value={value} onChange={handleOnChange} />;
};
