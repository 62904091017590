import React from 'react';
import { PUIButtonIcon } from 'ui';
import { TableCellNode } from '@lexical/table';
import { useTranslation } from 'react-i18next';
import s from './styles.module.scss';
import { useMenu } from './hooks';

type MenuProps = {
  closeMenu: () => void;
  tableCellNode: TableCellNode;
};

export const Menu = ({ closeMenu, tableCellNode }: MenuProps) => {
  const { t } = useTranslation();
  const { menuRef, insertRow, insertColumn, deleteRow, deleteColumn, deleteTable } = useMenu(closeMenu, tableCellNode);

  return (
    <div className={s.menu} ref={menuRef}>
      <PUIButtonIcon icon="arrowUp" type="button" iconLeft className={s.button} onClick={() => insertRow(false)}>
        {t('insertRowAbove')}
      </PUIButtonIcon>
      <PUIButtonIcon icon="arrowDown" type="button" iconLeft className={s.button} onClick={() => insertRow(true)}>
        {t('insertRowBelow')}
      </PUIButtonIcon>
      <PUIButtonIcon icon="arrowRight" type="button" iconLeft className={s.button} onClick={() => insertColumn(true)}>
        {t('insertColumnRight')}
      </PUIButtonIcon>
      <PUIButtonIcon icon="arrowLeft" type="button" iconLeft className={s.button} onClick={() => insertColumn(false)}>
        {t('insertColumnLeft')}
      </PUIButtonIcon>
      <PUIButtonIcon icon="remove" type="button" iconLeft className={s.button} onClick={deleteRow}>
        {t('deleteRow')}
      </PUIButtonIcon>
      <PUIButtonIcon icon="remove" type="button" iconLeft className={s.button} onClick={deleteColumn}>
        {t('deleteColumn')}
      </PUIButtonIcon>
      <PUIButtonIcon icon="remove" type="button" iconLeft className={s.button} onClick={deleteTable}>
        {t('deleteTable')}
      </PUIButtonIcon>
    </div>
  );
};
