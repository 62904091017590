import { useClickOutside } from 'app/utils/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ColorChangeHandler } from 'react-color';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const defaultFontColor = '#000000';

export const useFontColor = () => {
  const [editor] = useLexicalComposerContext();

  const [fontColor, setFontColor] = useState(defaultFontColor);

  const fontColorDropdownRef = useRef<HTMLDivElement>(null);
  const fontColorButtonRef = useRef<HTMLButtonElement>(null);

  const [isFontColorDropdownOpen, setIsFontColorDropdownOpen] = useState(false);

  const closeFontColorDropdown = () => {
    setIsFontColorDropdownOpen(false);
  };

  const openFontColorDropdown = () => {
    setIsFontColorDropdownOpen(true);
  };

  useClickOutside(fontColorDropdownRef, closeFontColorDropdown);

  const changeFontColor: ColorChangeHandler = ({ hex }, e) => {
    // need this preventDefault to prevent the editor from losing focus of the selection
    e.preventDefault();
    editor.update(() => {
      const selection = $getSelection();

      if (selection !== null) {
        $patchStyleText(selection, {
          color: hex,
        });
      }
    });
  };

  const changeDefaultColor = (color: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    changeFontColor({ hex: color }, event);
  };

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      setFontColor($getSelectionStyleValueForProperty(selection, 'color', defaultFontColor));
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            $updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateToolbar();
          });
        }),
      ),
    [$updateToolbar, editor],
  );

  return {
    values: {
      fontColor,
      isFontColorDropdownOpen,
    },
    refs: {
      fontColorButtonRef,
      fontColorDropdownRef,
    },
    handlers: {
      openFontColorDropdown,
      closeFontColorDropdown,
      changeFontColor,
      changeDefaultColor,
    },
  };
};
