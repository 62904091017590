import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_TABLE_COMMAND } from '@lexical/table';
import { useState } from 'react';

export type InitialValues = {
  columns: number;
  rows: number;
};

const initialValues: InitialValues = {
  columns: 5,
  rows: 5,
};

export const MAX_ROWS = 10;
export const MIN_ROWS = 1;
export const MAX_COLUMNS = 10;
export const MIN_COLUMNS = 1;

export const useCreateTable = ({ closeDropdown }: { closeDropdown?: () => void }) => {
  const [editor] = useLexicalComposerContext();
  const [isTablePopupShown, setIsTablePopupShown] = useState(false);

  const showTablePopup = () => {
    setIsTablePopupShown(true);
  };

  const closeTablePopup = () => setIsTablePopupShown(false);

  const createTable = (values: InitialValues) => {
    const { columns, rows } = values;
    editor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns: columns.toString(),
      rows: rows.toString(),
      includeHeaders: false,
    });
    closeTablePopup();
    if (closeDropdown) closeDropdown();
  };

  return { isTablePopupShown, showTablePopup, closeTablePopup, createTable, initialValues };
};
