import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

type DisabledPluginProps = {
  disabled: boolean;
};

export const DisabledPlugin = ({ disabled }: DisabledPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!disabled);
  }, [disabled]);

  return null;
};
