/* eslint-disable no-nested-ternary */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { getSelectedNode } from 'app/components/core/editor/utils';
import { $isLinkNode } from '@lexical/link';
import AlignCenter from '../static/align_center.svg';
import AlignJustify from '../static/align_justify.svg';
import AlignLeft from '../static/align_left.svg';
import AlignRight from '../static/align_right.svg';

export type AlignOption = { value: ElementFormatType; label: ReactNode };

export const alignIconsMap = {
  left: AlignLeft,
  center: AlignCenter,
  right: AlignRight,
  justify: AlignJustify,
};

const options: Array<AlignOption> = [
  { value: 'left', label: 'left' },
  { value: 'center', label: 'center' },
  { value: 'right', label: 'right' },
  { value: 'justify', label: 'justify' },
];

export const useAlign = () => {
  const [editor] = useLexicalComposerContext();
  const [align, setAlign] = useState<AlignOption['value']>('left');

  const onChange = ({ value }: AlignOption) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, value);
  };

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
        );
      }

      // If matchingParent is a valid node, pass it's format type
      setAlign(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || 'left',
      );
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            $updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateToolbar();
          });
        }),
      ),
    [$updateToolbar, editor],
  );

  return {
    align,
    alignIconsMap,
    options,
    onChange,
  };
};
