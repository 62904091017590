import React from 'react';
import cx from 'classnames';
import TableIcon from './static/table.svg';
import { useCreateTable } from './hooks';
import { CreateTablePopup } from './components';
import s from './styles.module.scss';

type TableProps = {
  closeDropdown?: () => void;
};

export const Table = ({ closeDropdown }: TableProps) => {
  const { isTablePopupShown, showTablePopup, closeTablePopup, createTable, initialValues } = useCreateTable({
    closeDropdown,
  });

  return (
    <>
      <button
        className={cx('reset-button', s.button)}
        type="button"
        title="Insert table"
        aria-label="Insert table"
        onClick={showTablePopup}
      >
        <TableIcon />
      </button>
      {isTablePopupShown && (
        <CreateTablePopup closePopup={closeTablePopup} createTable={createTable} initialValues={initialValues} />
      )}
    </>
  );
};
