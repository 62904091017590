import React, { useMemo, useState } from 'react';
import theme from 'ui/utils/theme';
import { Icon, PUIText } from 'ui';
import cx from 'classnames';
import i18next, { t } from 'i18next';
import Locale from 'data/enums/Locale';

import s from './styles.module.scss';

interface LanguageSelectorProps {
  className?: string;
  color?: string;
  isFullNameVariant?: boolean;
}

const options = [
  { label: Locale.ENGLISH.toUpperCase(), value: Locale.ENGLISH },
  { label: Locale.DUTCH.toUpperCase(), value: Locale.DUTCH },
  { label: Locale.FRENCH.toUpperCase(), value: Locale.FRENCH },
];

const optionsWithFullNames = [
  { label: t('english'), value: Locale.ENGLISH },
  { label: t('dutch'), value: Locale.DUTCH },
  { label: t('french'), value: Locale.FRENCH },
];

export const LanguageSelector = ({
  className,
  color = theme.colors.base900,
  isFullNameVariant = false,
}: LanguageSelectorProps) => {
  const [isDropDownShown, setDropDownShown] = useState(false);
  const currentOptions = isFullNameVariant ? optionsWithFullNames : options;

  const currentOption =
    useMemo(() => currentOptions.find((option) => option.value === i18next.language), [i18next.language]) ||
    currentOptions[0];

  const closeDropDown = () => {
    setDropDownShown(false);
  };

  const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // Capture the current target before the setTimeout
    const { currentTarget } = event;

    // Delay execution to allow time for the onClick event to fire on the dropdown options
    setTimeout(() => {
      // Use the captured currentTarget instead of event.currentTarget
      if (!currentTarget.contains(event.relatedTarget)) {
        setDropDownShown(false);
      }
    }, 0);
  };

  const handleInputClick = () => {
    setDropDownShown(!isDropDownShown);
  };

  const onChange = (option: { label: string; value: string }) => {
    // change language only if user select other
    if (i18next.language !== option.value) i18next.changeLanguage(option.value);
    closeDropDown();
  };

  return (
    <div
      className={cx(s.languageContainer, className)}
      tabIndex={0}
      role="button"
      onBlur={onBlur}
      onKeyDown={handleInputClick}
    >
      <button className={s.inputWrapper} onClick={handleInputClick}>
        <PUIText className={s.capitalize} color={color}>
          {currentOption.label}
        </PUIText>
        <Icon icon="triangleDown" size="sm" style={{ color }} className={cx(isDropDownShown && s.arrowUp)} />
      </button>
      {isDropDownShown && (
        <ul className={s.dropdown}>
          {currentOptions.map((option) => (
            <li key={option.value} className={cx(s.option)}>
              <button className={s.optionButton} onClick={() => onChange(option)}>
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
