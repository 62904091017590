/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ControlProps, components } from 'react-select';
import { PUIButtonIcon } from 'ui';
import { useTranslation } from 'react-i18next';
import { DynamicValueOption } from '../../types';
import s from './styles.module.scss';

export const SelectControl = (props: ControlProps<DynamicValueOption, false>) => {
  const { t } = useTranslation();

  const showSelect = () => {
    const { menuIsOpen, onMenuOpen, onMenuClose } = props.selectProps;
    // Trigger the original control's click event
    if (menuIsOpen) {
      onMenuClose?.();
    } else {
      onMenuOpen?.();
    }
  };

  return (
    <>
      <PUIButtonIcon type="button" icon="zap" onClick={showSelect} className={s.button}>
        {t('dynamicContent')}
      </PUIButtonIcon>
      <components.Control className={s.originalControl} {...props} />
    </>
  );
};
