import React from 'react';
import cx from 'classnames';
import LinkIcon from './static/link.svg';
import s from './styles.module.scss';
import { useLink } from './hooks';

export const Link = () => {
  const { insertLink, isLink } = useLink();

  return (
    <button
      type="button"
      title="Insert link"
      aria-label="Insert link"
      onClick={insertLink}
      className={cx('reset-button', s.button, isLink && s.buttonActive)}
    >
      <LinkIcon />
    </button>
  );
};
