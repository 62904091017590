// Source: https://stackoverflow.com/a/8234912/2013580

import { defaultLinkUrl } from '../constants';

// eslint-disable-next-line prefer-regex-literals
const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);

export const validateUrl = (url: string): boolean => url === defaultLinkUrl || urlRegExp.test(url);
