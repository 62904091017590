import React from 'react';
import cx from 'classnames';
import ImageIcon from './static/image.svg';
import s from './styles.module.scss';
import { useImage } from './hooks';

// Image upload button
export const Image = () => {
  const { fileInputRef, showFilePopup, uploadImage, isLoading } = useImage();

  return (
    <>
      <input ref={fileInputRef} onChange={uploadImage} className={s.fileInput} type="file" accept="image/*" />
      <button
        type="button"
        disabled={isLoading}
        title="Insert image"
        onClick={showFilePopup}
        aria-label="Insert image"
        className={cx('reset-button', s.button)}
      >
        <ImageIcon />
      </button>
    </>
  );
};
