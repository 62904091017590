import React from 'react';
import cx from 'classnames';
import OutdentIcon from './static/outdent.svg';
import { useOutdent } from './hooks';
import s from './styles.module.scss';

export const Outdent = () => {
  const { outdent } = useOutdent();

  return (
    <button
      className={cx('reset-button', s.button)}
      type="button"
      title="Outdent"
      aria-label="Insert table"
      onClick={outdent}
    >
      <OutdentIcon />
    </button>
  );
};
